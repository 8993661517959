import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CguComponent } from './cgu.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CguComponent],
  exports : [CguComponent]
})
export class CguModule { }
