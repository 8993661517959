<div *ngIf="!isFromBridge">
  <!-- TABLEAU ICV -->
  <div class="progress">
    <div class="progressbar-title">
      <h2 class="progress-title" *ngIf="!accesCollaborateur">Mes Informations</h2>
      <h2 class="progress-title" *ngIf="accesCollaborateur">Informations client *</h2>
      <p id = 'a'>{{ progressValue }}%</p>
    </div>
    <div
      aria-valuemin="0"
      role="progressbar"
      class="progress-bar progress-bar-striped"
      [attr.aria-valuenow]="progressValue"
      aria-valuemax="100"
      [style.width.%]="progressValue"
      aria-label="Mon espace client"
    ></div>
  </div>
  <div class="panelBody">
    <div *ngIf="showList">
      <h5 *ngIf="!isDashboard && !this.accesCollaborateur" style="text-align: center;">
        Parlons un peu de vous !<br />Nous parlerons de votre projet à la prochaine étape.
      </h5>
      <div *ngIf="!isDashboard && !this.accesCollaborateur">
        <p class="light">
          <span class="titreInfo">Pourquoi compléter mes informations à cette étape ?</span><br />
          <span
            >Ces informations complémentaires nous permettent d'affiner votre capacité d'emprunt et de vous proposer les offres les plus
            adaptées à votre situation.
          </span>
        </p>
      </div>
      <br /><br />
      <ul class="champRow clearfix">
        <!--
            INFO DEV : UNE FOIS L'INFORMATION REMPLIE PAR L'INTERNAUTE, LE <li> PREND LA CLASS "validated" AFIN QUE LE PETIT ROND DEVIENNE VIOLET AVEC L'ICONE VALIDE
            >> EXEMPLE ICI AVEC LE CHAMP "identité"
          -->
        <li [ngClass]="{ validated: identiteValid && identiteForm.valid }">
          <div class="completRow">
            <button (click)="showForm('identite');tagNavigation('Identite','Mes informations');tagView('Identite')">
              <i
                class="verticalAlign">Identité</i><i class="btnValid btnValidation"
                                                     [ngStyle]="{'visibility': identiteValid && identiteForm.valid ? 'visible' : 'hidden'}">Complété</i><i
              class="btnInvalid btnValidation"
              [ngStyle]="{'visibility': !identiteValid || !identiteForm.valid ? 'visible' : 'hidden'}">À
              compléter</i>
            </button>
          </div>
        </li>
        <li [ngClass]="{ validated: emprunt1ou2Valid && emprunt1ou2Form.valid }">
          <div class="completRow">
            <button
              (click)="showForm('emprunt1ou2');">
              <i *ngIf="!emprunt1ou2Valid || emprunt1ou2FormControls.emprunt1ou2Val.value==0"
                 class="verticalAlign">J’emprunte seul(e) ou à
                deux</i>
              <i *ngIf="emprunt1ou2Valid && emprunt1ou2FormControls.emprunt1ou2Val.value!=0"
                 class="verticalAlign">{{emprunt1ou2Libelle}}</i>
              <i class="btnValid btnValidation"
                 [ngStyle]="{'visibility': emprunt1ou2Valid && emprunt1ou2Form.valid  ? 'visible' : 'hidden'}">Complété</i><i
              class="btnInvalid btnValidation"
              [ngStyle]="{'visibility':  !emprunt1ou2Valid || !emprunt1ou2Form.valid ? 'visible' : 'hidden'}">À
              compléter</i></button>
          </div>
        </li>
        <li [ngClass]="{ validated: habitationValid && habitationForm.valid }">
          <div class="completRow">
            <button (click)="showForm('habitation');tagNavigation('Lieu de vie','Mes informations');tagView('Lieu de vie')"><i class="verticalAlign">Lieu de vie</i><i class="btnValid btnValidation" [ngStyle]="{'visibility': habitationValid && habitationForm.valid ? 'visible' : 'hidden'}">Complété</i><i class="btnInvalid btnValidation" [ngStyle]="{'visibility': !habitationValid || !habitationForm.valid ? 'visible' : 'hidden'}">À compléter</i></button>
          </div>
        </li>
        <li [ngClass]="{ validated: situationProValid && situationProForm.valid }">
          <div class="completRow">
            <button (click)="showForm('situationProfessionnelle');tagNavigation('Situation professionnelle','Mes informations');tagView('Situation professionnelle')"><i class="verticalAlign">Situation professionnelle</i><i class="btnValid btnValidation" [ngStyle]="{'visibility': situationProValid && situationProForm.valid ? 'visible' : 'hidden'}">Complété</i><i class="btnInvalid btnValidation" [ngStyle]="{'visibility': !situationProValid || !situationProForm.valid ? 'visible' : 'hidden'}">À compléter</i></button>
          </div>
        </li>
        <li *ngIf="displayMenuForBridge()" [ngClass]="{ validated: revenusValid && revenusForm.valid }">
          <div class="completRow">
            <button (click)="showForm('revenus');tagNavigation('Revenus','Mes informations');tagView('Revenus')"><i class="verticalAlign">Revenus</i><i class="btnValid btnValidation" [ngStyle]="{'visibility': revenusValid && revenusForm.valid ? 'visible' : 'hidden'}">Complété</i><i class="btnInvalid btnValidation" [ngStyle]="{'visibility': !revenusValid || !revenusForm.valid ? 'visible' : 'hidden'}">À compléter</i></button>
          </div>
        </li>
        <li *ngIf="displayMenuForBridge()" [ngClass]="{ validated: chargesValid && chargesForm.valid }">
          <div class="completRow">
            <button (click)="showForm('charges');tagNavigation('Charges','Mes informations');tagView('Charges')"><i class="verticalAlign">Charges</i><i class="btnValid btnValidation" [ngStyle]="{'visibility': chargesValid && chargesForm.valid ? 'visible' : 'hidden'}">Complété</i><i class="btnInvalid btnValidation" [ngStyle]="{'visibility': !chargesValid || !chargesForm.valid ? 'visible' : 'hidden'}">À compléter</i></button>
          </div>
        </li>
      </ul>
    </div>

    <div class="infos-content">
      <div id="identite" *ngIf="showIdentite">
        <div class="infosContent-title">
          <div class="previous-text" tabindex="0" appAriaClick role="button" (click)="showListTabs('identite');tagNavigation('Fermer','Identite');">
            <div class="previous"><span class="texte">Retour</span></div>
          </div>
          <h2 class="h5-flex text-center" *ngIf="!accesCollaborateur">Saisissez vos informations d'identité<br /></h2>
          <h2 class="h5-flex text-center" *ngIf="accesCollaborateur">Saisissez les informations d'identité<br /></h2>
        </div>
        <form [formGroup]="identiteForm" (ngSubmit)="validerIdentite()">
          <p class="mandatory">*Informations obligatoires</p>
          <div class="completeChamp clearfix">
            <div class="partLeft">
              <div class="form-group">
                <label class="control-label" for="civilite">Civilité*</label>
                <select [disabled]='readOnly' id="civilite" class="form-control" formControlName="sexe" name="sexe" autocomplete="honorific-prefix">
                  <option value="" [disabled]='readOnly' selected hidden>Sélectionnez</option>
                  <option value="HOMME">Monsieur</option>
                  <option value="FEMME">Madame</option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label" for="lastName">Nom*</label>
                <input type="text" [disabled]='readOnly' class="form-control" id="lastName" name="lastName" placeholder="Votre nom" formControlName="lastName" autocomplete="family-name" />
                <div *ngIf="identiteFormControls.lastName.dirty && identiteFormControls.lastName.errors">
                  <p class="help-block" *ngIf="identiteFormControls.lastName.errors.required">Votre nom est obligatoire.</p>
                  <p class="help-block" *ngIf="identiteFormControls.lastName.errors.minlength">
                    Votre nom doit comporter au moins 1 caractère.
                  </p>
                  <p class="help-block" *ngIf="identiteFormControls.lastName.errors.maxlength">
                    Votre nom ne peut pas dépasser 50 caractères.
                  </p>
                  <p class="help-block" *ngIf="identiteFormControls.lastName.errors.pattern">Saisie incorrecte.</p>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label" for="firstName">Prénom*</label>
                <input
                  type="text"
                  [disabled]='readOnly'
                  class="form-control"
                  id="firstName"
                  name="firstName"
                  placeholder="Votre prénom"
                  formControlName="firstName"
                  autocomplete="given-name"
                />
                <div *ngIf="identiteFormControls.firstName.dirty && identiteFormControls.firstName.errors">
                  <p class="help-block" *ngIf="identiteFormControls.firstName.errors.required">Votre prénom est obligatoire.</p>
                  <p class="help-block" *ngIf="identiteFormControls.firstName.errors.minlength">
                    Votre nom doit comporter au moins 1 caractère.
                  </p>
                  <p class="help-block" *ngIf="identiteFormControls.firstName.errors.maxlength">
                    Votre nom ne peut pas dépasser 50 caractères.
                  </p>
                  <p class="help-block" *ngIf="identiteFormControls.firstName.errors.pattern">Saisie incorrecte.</p>
                </div>
              </div>
            </div>
            <div class="partRight">
              <div class="form-group">
                <label class="control-label" for="field_situationFamiliale">Situation familiale*</label>
                <select class="form-control" name="situationFamiliale" formControlName="situationFamiliale" id="field_situationFamiliale">
                  <option value="" [disabled]='readOnly' selected hidden>Sélectionnez</option>
                  <option value="CELIBATAIRE">Célibataire</option>
                  <option value="MARIE">Marié(e)</option>
                  <option value="PACSE">Pacsé(e)</option>
                  <option value="DIVORCE">Divorcé(e)</option>
                  <option value="VEUF">Veuf(ve)</option>
                  <option value="CONCUBINAGE">Concubinage</option>
                  <option value="SEPARE">Séparé(e)</option> </select
                ><br />
                <p class="error" *ngIf="coupleToSeul">
                  Attention, suite au changement de situation, les informations de votre conjoint seront supprimées.
                </p>
              </div>
              <div class="form-group">
                <label class="control-label" for="field_nombreEnfant">Nombre d'enfants à charge*</label>
                <input type="number" class="form-control" name="nombreEnfant" id="field_nombreEnfant" formControlName="nombreEnfants" />
                <div *ngIf="identiteFormControls.nombreEnfants.dirty && identiteFormControls.nombreEnfants.errors">
                  <p class="help-block" *ngIf="identiteFormControls.nombreEnfants.errors.required">
                    Nombre d'enfants à charge obligatoire.
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label" for="dateNaissance">Date de naissance* (format : jj/mm/aaaa)</label>
                <input
                [disabled]='readOnly'
                  type="text"
                  class="form-control"
                  id="dateNaissance"
                  name="dateNaissance"
                  placeholder="jj/mm/aaaa"
                  formControlName="dateNaissance"
                  autocomplete="bday"
                  appDateMask
                />
                <div *ngIf="identiteFormControls.dateNaissance.dirty && identiteFormControls.dateNaissance.errors">
                  <p class="help-block" *ngIf="identiteFormControls.dateNaissance.errors.required">Date Naissance obligatoire.</p>
                  <p class="help-block" *ngIf="identiteFormControls.dateNaissance.errors.pattern">Date Naissance invalide, (exemple : 02/05/1980)</p>
                </div>
                <div *ngIf="!identiteFormControls.dateNaissance.errors">
                  <p class="help-block" *ngIf="mineur">Si vous avez moins de 18 ans votre capacité de crédit ne peut être calculée.</p>
                  <p class="help-block" *ngIf="dateNaissanceInvalide">La date de naissance ne doit pas être inférieure au 01/01/1900.</p>
                </div>
              </div>
              <div class="form-group" *ngIf="enCouple">
                <label class="control-label" for="field_ageConjoint">Âge de votre conjoint* (en chiffre)</label>
                <input type="text" class="form-control" name="ageConjoint"
                id="field_ageConjoint"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="2" (change)="validerAgeConjoint()"
                formControlName="ageConjoint" />
                <p class="help-block" *ngIf="enCouple && identiteForm.value.ageConjoint != '' && !validerAgeConjoint()">
                  Âge de conjoint invalide.
                </p>
                <p class="help-block"  *ngIf="enCouple && ((identiteFormControls.ageConjoint.dirty || identiteFormControls.ageConjoint.touched) && identiteForm.value.ageConjoint === '' || identiteForm.value.ageConjoint == null)" >
                  Âge de conjoint obligatoire.
                </p>
              </div>
            </div>
          </div>

          <div class="inscriptionValidation">
            <button
              type="submit"
              [disabled]="!identiteForm.valid || dateNaissanceInvalide || (enCouple && (identiteForm.value.ageConjoint === '' || !validerAgeConjoint() || identiteForm.value.ageConjoint == null))"
              class="w300 mAuto"
            >

              Valider
            </button>
          </div>
        </form>
      </div>
      <div id="habitation" *ngIf="showHabitation">
        <div class="infosContent-title">
          <div class="previous-text" tabindex="0" appAriaClick  (click)="showListTabs('habitation');tagNavigation('Fermer','Lieu de vie');">
            <div class="previous"><span class="texte">Retour</span></div>
          </div>
          <h2 class="h5-flex text-center" *ngIf="!accesCollaborateur">Saisissez vos informations d'habitation<br /></h2>
          <h2 class="h5-flex text-center" *ngIf="accesCollaborateur">Saisissez les informations d'habitation<br /></h2>
        </div>
        <form [formGroup]="habitationForm" (ngSubmit)="validerHabitation()">
          <p class="mandatory">*Informations obligatoires</p>
          <div class="completeChamp clearfix">
            <div class="partLeft">
              <div class="form-group">
                <label class="control-label">Habitation principale*</label>
                <select class="form-control" formControlName="typeHabitation" id="field_habitant" aria-label="Type Habitation">
                  <option value="" disabled selected hidden>Sélectionnez</option>
                  <option value="PROPRIETAIRE">Propriétaire</option>
                  <option value="LOCATAIRE">Locataire</option>
                  <option value="LOCATAIRE_HLM">Locataire HLM</option>
                  <option value="LOCATAIRE_GRATUIT">Logé à titre gratuit</option>
                  <option value="LOGE_EMPLOYEUR">Logé par l'employeur</option>
                  <option value="AUTRE">Autre</option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label" for="codePostal"
                  >Code postal* (format : 00000)
                  <button mat-icon-button
                          aria-label="Code postal info-bulle"
                          matTooltip="Les offres de crédit proposées s'adressent à des résidents français domiciliés en France métropolitaine." class="customTooltip customInfoTooltip">
                    <mat-icon class="tooltipStyle">info</mat-icon>
                  </button>
                </label>
                <input type="text" class="form-control" formControlName="codePostal" id="codePostal" (focus)="initMessageErreur()" name="codePostal" autocomplete="postal-code"/>
                <div *ngIf="habitationFormControls.codePostal.dirty && habitationFormControls.codePostal.errors">
                  <div class="help-block" *ngIf="habitationFormControls.codePostal.errors.required">Code postal obligatoire.</div>
                  <div
                    class="help-block"
                    *ngIf="habitationFormControls.codePostal.errors.minlength || habitationFormControls.codePostal.errors.maxlength"
                  >
                    Le code postal doit contenir 5 caractères, (ex : 75001)
                  </div>
                </div>
              </div>
              <div class="help-block" *ngIf="cpDomTom">
                Les offres de crédit proposées s'adressent à des résidents français domiciliés en France métropolitaine. Renseigner un code postal valide (exemple : 75001).
              </div>
              <div class="help-block" *ngIf="cpInvalid && habitationForm.value.codePostal != '' && !cpDomTom">
                le code postal que vous avez saisi ne correspond pas à une commune française
              </div>
            </div>
            <div class="partRight">
              <div class="form-group">
                <label class="control-label">Année d'entrée dans l'habitation* (format : 0000)</label>
                <input
                  type="text"
                  class="form-control"
                  name="anneeEntreeHabitation"
                  id="anneeEntreeHabitation"
                  placeholder="aaaa"
                  formControlName="anneeEntreeHabitation"
                  maxlength="4"
                />
              </div>

              <div
                class="help-block"
                *ngIf="
                  !anneeEntreeHabitationValid ||
                  (habitationFormControls.anneeEntreeHabitation.dirty &&
                    habitationForm.value.anneeEntreeHabitation != '' &&
                    habitationForm.value.anneeEntreeHabitation.length === 4 &&
                    habitationForm.value.anneeEntreeHabitation < yearBirth)
                "
              >
                Saisie incorrecte
              </div>
              <div *ngIf="habitationFormControls.anneeEntreeHabitation.dirty && habitationFormControls.anneeEntreeHabitation.errors">
                <span class="help-block" *ngIf="habitationFormControls.anneeEntreeHabitation.errors.pattern">Saisie incorrecte</span>
                <span class="help-block" *ngIf="habitationFormControls.anneeEntreeHabitation.errors.required"
                  >Année d'entrée dans l'habitation obligatoire</span
                >
                <span
                  class="help-block"
                  *ngIf="
                    habitationFormControls.anneeEntreeHabitation.errors.maxlength ||
                    habitationFormControls.anneeEntreeHabitation.errors.minlength
                  "
                  >Année d'entrée doit contenir 4 chiffres (ex: 1992)</span
                >
              </div>
            </div>
          </div>

          <div class="inscriptionValidation">
            <button
              type="submit"
              [disabled]="
                !habitationForm.valid ||
                !anneeEntreeHabitationValid ||
                cpInvalid ||
                cpDomTom ||
                habitationForm.value.anneeEntreeHabitation < yearBirth
              "
              class="w300 mAuto"
              (click)="validerCP()"
            >
              Valider
            </button>
          </div>
        </form>
      </div>


      <div id="bridgeOrClassic" *ngIf="showBridgeOrClassic" class="sitautionPro">
        <div class="infosContent-title">
          <div class="previous-text" tabindex="0" appAriaClick
               (click)="showListTabs('bridgeOrClassic');tagNavigationDSP2('Fermer','choix methode de calcul capacite emprunt');">
            <div class="previous"><span class="texte">Retour</span></div>
          </div>
        </div>
        <form [formGroup]="bridgeOrClassicForm" (ngSubmit)="validerBridgeOrClassic()">

          <div class="row">
            <h2 class="col-12 text-center">A vous de choisir !</h2>
            <span class="col-12 text-center bridgesubtext">Nous vous proposons 2 méthodes au choix, pour que nous puissions calculer votre capacité d'emprunt définitive:</span>


            <mat-radio-group aria-label="Select an option" formControlName="bridgeOrClassicVal"
                             class="bridgeorcla  ssic-radio-group">
              <mat-radio-button class="bridgeorclassic-radio-group" value="bridge">
                <div>

                  <p class="bridgeicon">

                    <span> <small>Traitement plus rapide de votre dossier par nos attachés commerciaux</small></span><br>
                    <span class="bridgeParcourstext-1"> <strong> Estimer vos charges et revenus via notre partenaire Bridge </strong></span><br>
                    <span class="bridgeParcourstext-2">Vous serez alors invités à vous connecter à l'application bancaire de votre banque prinncipale qui nous transmettra l'ensemble de vos opérations des 90 derniers jours.</span>
                    <br/>
                    <span><a class="coleur-ensavoir-plus" (click)="$event.preventDefault();goToQuiSommesNous()"
                             role="link">En savoir + sur bridge</a></span>
                  </p>
                </div>
              </mat-radio-button>

              <mat-radio-button class="bridgeorclassic-radio-group" value="classic">
                <div>
                  <p class="classicicon">
                    <span class="bridgeParcourstext-1"><strong>Renseigner manuellement vos revenus et charges ainsi que vos coordonnées bancaires</strong></span><br>
                    <span class="bridgeParcourstext-2"> (RIB faisant apparaître votre BIC et IBAN) afin que nos attachés commerciaux étudient votre demande </span><br>

                  </p>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="inscriptionValidation">
            <button
              type="submit"
              [disabled]="
                !bridgeOrClassicForm.valid ||
                bridgeOrClassicForm.value.bridgeOrClassicVal === null || bridgeOrClassicForm.value.bridgeOrClassicVal === ''
              "
              class="w300 mAuto">
              Valider
            </button>
          </div>
        </form>

      </div>

      <div id="emprunt1ou2" *ngIf="showEmprunt1ou2" class="sitautionPro">
        <div class="infosContent-title">
          <div class="previous-text" tabindex="0" appAriaClick
               (click)="showListTabs('emprunt1ou2');tagNavigation('Fermer','J’emprunte seul(e) où à deux');">
            <div class="previous"><span class="texte">Retour</span></div>
          </div>
          <h5 class="h5-flex text-center" *ngIf="!accesCollaborateur">Vous souhaitez emprunter<br/></h5>
          <h5 class="h5-flex text-center" *ngIf="accesCollaborateur">Vous souhaitez emprunter<br/></h5>
        </div>
        <form [formGroup]="emprunt1ou2Form" (ngSubmit)="validerEmprunt1ou2()">
          <div class="row">
            <div class="col-10">
              <div class="form-group">
                <h4 class="control-label">Seul</h4>
              </div>
              <div class="form-group">
                <h4 class="control-label">Avec un co-emprunteur</h4>
              </div>

            </div>


            <div class="col-2">

              <mat-radio-group aria-label="Select an option" formControlName="emprunt1ou2Val"
                               (change)="changeEmprunt1ou2()" class="emprunt1ou2-radio-group">
                               <mat-radio-button class="form-group" value="1"  ></mat-radio-button>
                               <mat-radio-button class="form-group marginTop16" value="2" [disabled]="!enCouple"  ></mat-radio-button>
              </mat-radio-group>
            </div>

          </div>
          <div class="inscriptionValidation">
            <button
              type="submit"
              [disabled]="
                !emprunt1ou2Form.valid ||
                emprunt1ou2Form.value.emprunt1ou2Val === null || emprunt1ou2Form.value.emprunt1ou2Val ===0
              "
              class="w300 mAuto">
              Valider
            </button>
          </div>
        </form>

      </div>
      <div id="situationProfessionnelle" *ngIf="showSituationProfessionnelle" class="sitautionPro">
        <div class="infosContent-title">
          <div class="previous-text" tabindex="0" appAriaClick (click)="showListTabs('situationProfessionnelle');tagNavigation('Fermer','Situation professionnelle');">
            <div class="previous"><span class="texte">Retour</span></div>
          </div>
        </div>
        <ngb-tabset type="pills" justify="center" class="tabs" #situationPro (keyup.arrowleft)="navigationFleche($event, false)"
          (keyup.arrowright)="navigationFleche($event, true)" (keyup.Space)="selectTab($event)" (tabChange)="tabChangeEvent()">
          <ngb-tab id="vous" title="Vous">
            <ng-template ngbTabContent>
              <div class="infosContent-title">
                <h2 class="h5-flex text-center" *ngIf="!accesCollaborateur">Saisissez vos informations professionnelles<br /></h2>
                <h2 class="h5-flex text-center" *ngIf="accesCollaborateur">Saisissez les informations professionnelles<br /></h2>
              </div>
              <form [formGroup]="situationProForm" (ngSubmit)="conjointForm()">
                <p class="mandatory">*Informations obligatoires</p>
                <div class="completeChamp clearfix">
                  <div class="partLeft">
                    <div class="form-group">
                      <label class="control-label" for="csp">Profession*</label>
                      <div>
                        <select name="csp" id="csp" formControlName="csp" class="form-control">
                          <option value="" disabled selected hidden>Sélectionnez</option>
                          <option *ngFor="let csp of listeCspDetail" [value]="csp.code">{{ csp.libelle }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label" for="field_nafAgrege">Secteur d'activité*</label>
                      <select class="form-control" name="nafAgrege" formControlName="secteurActivite" id="field_nafAgrege">
                        <option value="" disabled selected hidden>Sélectionnez</option>
                        <option *ngFor="let naf of listeNafA" [value]="naf.code">{{ naf.libelle }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="partRight">
                    <div class="form-group">
                      <label class="control-label" for="field_anneeDebutPro"
                        >Année d'embauche ou de début d'activité*
                        <button mat-icon-button
                                aria-label="Année d'embauche info-bulle"
                                matTooltip="Indiquez l'année d'entrée chez votre employeur actuel en cas de profession salariée ou l'année de début
                            d'activité en cas de profession non salariée." class="customTooltip customInfoTooltip">
                          <mat-icon class="tooltipStyle">info</mat-icon>
                        </button>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="anneeDebutPro"
                        id="field_anneeDebutPro"
                        formControlName="anneeDebutPro"
                        placeholder="aaaa"
                      />
                    </div>
                    <div *ngIf="!anneeDebutProValid && situationProForm.value.anneeDebutPro != ''" class="help-block">
                      Saisie incorrecte
                    </div>
                    <div *ngIf="situationProFormControls.anneeDebutPro.dirty && situationProFormControls.anneeDebutPro.errors">
                      <span class="help-block" *ngIf="situationProFormControls.anneeDebutPro.errors.pattern">Saisie incorrecte</span>
                      <span class="help-block" *ngIf="situationProFormControls.anneeDebutPro.errors.required"
                        >Année d'embauche obligatoire</span
                      >
                      <span
                        class="help-block"
                        *ngIf="
                          situationProFormControls.anneeDebutPro.errors.maxlength || situationProFormControls.anneeDebutPro.errors.minlength
                        "
                        >Année de début doit contenir 4 chiffres (ex: 1992)</span
                      >
                    </div>
                    <div class="form-group">
                      <label class="control-label" for="field_typeContrat">Type de contrat*</label>
                      <select class="form-control" name="typeContrat" formControlName="typeContrat" id="field_typeContrat">
                        <option value="" disabled selected hidden>Sélectionnez</option>
                        <option value="CDI">CDI</option>
                        <option value="CDD">CDD / Intérimaire</option>
                        <option value="AUTRE">Autre</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="inscriptionValidation">
                  <button
                    type="submit"
                    *ngIf="enCouple"
                    [disabled]="!situationProForm.valid || !anneeDebutProValid"
                    class="fullSize w300 mAuto"  >
                    Suivant
                  </button>
                </div>
              </form>
              <div class="inscriptionValidation">
                <button
                  type="submit"
                  *ngIf="!enCouple"
                  [disabled]="!situationProForm.valid || !anneeDebutProValid"
                  class="fullSize w300 mAuto"
                  (click)="validerSituationPro()"
                >
                  Valider
                </button>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="conjoint" title="Votre conjoint" *ngIf="enCouple">
            <ng-template ngbTabContent>
              <div class="infosContent-title">
                <h5 class="h5-flex text-center" *ngIf="!accesCollaborateur">
                  Saisissez les informations professionnelles de votre conjoint<br />
                </h5>
                <h5 class="h5-flex text-center" *ngIf="accesCollaborateur">
                  Saisissez les informations professionnelles du conjoint<br />
                </h5>
              </div>
              <form [formGroup]="situationProConjointForm" (ngSubmit)="validerSituationPro()">
                <p class="mandatory">*Informations obligatoires</p>
                <div class="completeChamp clearfix">
                  <div class="partLeft">
                    <div class="form-group">
                      <label class="control-label" for="csp">Profession*</label>
                      <div>
                        <select name="csp" id="csp" formControlName="cspConjoint" class="form-control">
                          <option value="" disabled selected hidden>Sélectionnez</option>
                          <option *ngFor="let csp of listeCspDetail" [value]="csp.code">{{ csp.libelle }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label" for="field_nafAgrege">Secteur d'activité*</label>
                      <select class="form-control" name="nafAgrege" formControlName="secteurActiviteConjoint" id="field_nafAgrege">
                        <option value="" disabled selected hidden>Sélectionnez</option>
                        <option *ngFor="let naf of listeNafA" [value]="naf.code">{{ naf.libelle }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="partRight">
                    <div class="form-group">
                      <label class="control-label" for="field_anneeDebutPro"
                        >Année d'embauche ou de début d'activité*
                        <button mat-icon-button
                                aria-label="Année d'embauche info-bulle"
                                matTooltip="Indiquez l'année d'entrée chez votre employeur actuel en cas de profession salariée ou l'année de début
                            d'activité en cas de profession non salariée." class="customTooltip customInfoTooltip">
                          <mat-icon class="tooltipStyle">info</mat-icon>
                        </button>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        name="anneeDebutPro"
                        id="field_anneeDebutPro"
                        formControlName="anneeDebutProConjoint"
                        placeholder="aaaa"
                      />
                    </div>

                    <div
                      *ngIf="!anneeDebutProConjointValid && situationProConjointForm.value.anneeDebutProConjoint != ''"
                      class="help-block"
                    >
                      Saisie incorrecte
                    </div>
                    <div
                      *ngIf="
                        situationProConjointFormControls.anneeDebutProConjoint.dirty &&
                        situationProConjointFormControls.anneeDebutProConjoint.errors
                      "
                    >
                      <span class="help-block" *ngIf="situationProConjointFormControls.anneeDebutProConjoint.errors.pattern"
                        >Saisie incorrecte</span
                      >
                      <span class="help-block" *ngIf="situationProConjointFormControls.anneeDebutProConjoint.errors.required"
                        >Année d'embauche obligatoire</span
                      >
                      <span
                        class="help-block"
                        *ngIf="
                          situationProConjointFormControls.anneeDebutProConjoint.errors.maxlength ||
                          situationProConjointFormControls.anneeDebutProConjoint.errors.minlength
                        "
                        >Année de début doit contenir 4 chiffres (ex: 1992)</span
                      >
                    </div>

                    <div class="form-group">
                      <label class="control-label" for="field_typeContratConjoint">Type de contrat*</label>
                      <select class="form-control" name="typeContrat" formControlName="typeContratConjoint" id="field_typeContratConjoint">
                        <option value="" disabled selected hidden>Sélectionnez</option>
                        <option value="CDI">CDI</option>
                        <option value="CDD">CDD / Intérimaire</option>
                        <option value="AUTRE">Autre</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="inscriptionValidation">
                  <button
                    type="submit"
                    *ngIf="enCouple"
                    [disabled]="
                      (!this.enCouple && (!this.situationProForm.valid || !this.anneeDebutProValid)) ||
                      (this.enCouple &&
                        (!this.situationProConjointForm.valid ||
                          !this.anneeDebutProConjointValid ||
                          !this.situationProForm.valid ||
                          !this.anneeDebutProValid))
                    "
                    class="fullSize w300 mAuto">
                    Valider
                  </button>
                </div>
              </form>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
      <div id="revenus" *ngIf="showRevenus">
        <div class="infosContent-title">
          <div class="previous-text" tabindex="0" appAriaClick (click)="showListTabs('revenus');tagNavigation('Fermer','Revenus');">
            <div class="previous"><span class="texte">Retour</span></div>
          </div>
          <h2 class="h5-flex text-center" *ngIf="!accesCollaborateur">Saisissez vos revenus mensuels nets avant impôt<br /></h2>
          <h2 class="h5-flex text-center" *ngIf="accesCollaborateur">Saisissez les revenus mensuels nets avant impôt<br /></h2>
        </div>

        <form [formGroup]="revenusForm" (ngSubmit)="validerRevenus()">
          <p class="mandatory">*Informations obligatoires</p>
          <div class="completeChamp clearfix">
            <div class="partLeft">
              <div class="form-group">
                <label class="control-label" for="field_montantRevenus"
                  >Revenus mensuels nets avant impôt*
                  <button mat-icon-button
                          aria-label="Revenus mensuels nets avant impôt info-bulle"
                          matTooltip="Indiquez les revenus nets mensuels avant impôt hors prime, bonus, variable, du travail ou perçus par des droits
                      acquis par le travail (préretraite et retraite). Hors conjoint. Sur une base de 12 mois (à zéro si aucun revenu
                      perçu)." class="customTooltip customInfoTooltip">
                    <mat-icon class="tooltipStyle">info</mat-icon>
                  </button>
                </label>
                <input
                  placeholder="€"
                  type="number"
                  class="form-control"
                  name="montantRevenus"
                  id="field_montantRevenus"
                  formControlName="montantRevenus"
                  min="0"
                />

                <div *ngIf="revenusFormControls.montantRevenus.dirty && revenusFormControls.montantRevenus.errors">
                  <span class="help-block" *ngIf="revenusFormControls.montantRevenus.errors.required"
                    >Revenus mensuels nets / mois obligatoires</span
                  >
                  <span class="help-block" *ngIf="revenusFormControls.montantRevenus.errors.min">Saisie incorrecte</span>
                </div>
              </div>
              <div class="form-group" *ngIf="enCouple">
                <label class="control-label" for="field_montantRevenusConjoint">
                  Revenus mensuels nets avant impôt de votre conjoint*
                  <button mat-icon-button
                          aria-label="Revenus mensuels nets avant impôt de votre conjoin info-bulle"
                          matTooltip="Indiquez les revenus nets mensuels avant impôt hors prime, bonus, variable, du travail ou perçus par des droits
                      acquis par le travail (préretraite et retraite) de votre conjoint. Sur une base de 12 mois (à zéro si aucun revenu
                      perçu)." class="customTooltip customInfoTooltip">
                    <mat-icon class="tooltipStyle">info</mat-icon>
                  </button>
                </label>
                <input
                  placeholder="€"
                  type="number"
                  class="form-control"
                  name="montantRevenusConjoint"
                  id="field_montantRevenusConjoint"
                  formControlName="montantRevenusConjoint"
                  min="0"
                />
                <div *ngIf="revenusFormControls.montantRevenusConjoint.dirty">
                  <span
                    class="help-block"
                    *ngIf="revenusForm.value.montantRevenusConjoint === '' || revenusForm.value.montantRevenusConjoint === null"
                    >Revenus mensuels nets / mois du conjoint obligatoires</span
                  >
                  <span class="help-block" *ngIf="revenusForm.value.montantRevenusConjoint < 0">Saisie incorrecte</span>
                </div>
              </div>
            </div>
            <div class="partRight">
              <div class="form-group">
                <label class="control-label" for="field_autresRevenus"
                  >Autres Revenus /mois*
                  <button mat-icon-button
                          aria-label="Autres Revenus /mois info-bulle"
                          matTooltip="Indiquez vos revenus mensuels du capital, de la propriété - Hors prestation(s) sociale(s) (APL, allocations
                      familiales...), hors pension alimentaire, hors pension de reversion... (à zéro si aucun revenu perçu)." class="customTooltip customInfoTooltip">
                    <mat-icon class="tooltipStyle">info</mat-icon>
                  </button>
                </label>
                <input
                  placeholder="€"
                  type="number"
                  class="form-control"
                  name="autresRevenus"
                  id="field_autresRevenus"
                  formControlName="autresRevenus"
                />
                <div *ngIf="revenusFormControls.autresRevenus.dirty && revenusFormControls.autresRevenus.errors">
                  <span class="help-block" *ngIf="revenusFormControls.autresRevenus.errors.required"
                    >Autres Revenus /mois obligatoires</span
                  >
                  <span class="help-block" *ngIf="revenusFormControls.autresRevenus.errors.min">Saisie incorrecte</span>
                </div>
              </div>
            </div>
          </div>

          <div class="inscriptionValidation">
            <button
              type="submit"
              [disabled]="
                !revenusForm.valid ||
                ((revenusForm.value.montantRevenusConjoint === '' ||
                  revenusForm.value.montantRevenusConjoint === null ||
                  revenusForm.value.montantRevenusConjoint === '' ||
                  revenusForm.value.montantRevenusConjoint < 0) &&
                  enCouple)
              "
              class="w300 mAuto">
              Valider
            </button>
          </div>
        </form>
      </div>
      <div id="charges" *ngIf="showCharges">
        <div class="infosContent-title">
          <div class="previous-text" tabindex="0" appAriaClick (click)="showListTabs('charges');tagNavigation('Fermer','Charges');">
            <div class="previous"><span class="texte">Retour</span></div>
          </div>
          <h2 class="h5-flex text-center" *ngIf="!accesCollaborateur">Saisissez vos charges mensuelles<br /></h2>
          <h2 class="h5-flex text-center" *ngIf="accesCollaborateur">Saisissez les charges mensuelles<br /></h2>
        </div>
        <form [formGroup]="chargesForm" (ngSubmit)="validerCharges()">
          <p class="mandatory">*Informations obligatoires</p>
          <div class="completeChamp clearfix">
            <div class="partLeft">
              <div class="form-group">
                <label class="control-label" for="field_montantChargeResidencePrin"
                  >Résidence principale (loyer / crédit immobilier)*
                  <button mat-icon-button
                          aria-label="info-bulle"
                          matTooltip="Si vous êtes locataire de votre résidence principale, indiquer votre loyer y compris les charges. Si vous êtes
                      propriétaire, indiquez les mensualités de votre prêt immobilier." class="customTooltip customInfoTooltip">
                    <mat-icon class="tooltipStyle">info</mat-icon>
                  </button>
                </label>

                <input
                  type="number"
                  class="form-control"
                  placeholder="€"
                  name="montantChargeResidencePrin"
                  id="field_montantChargeResidencePrin"
                  formControlName="montantChargeResidencePrin"
                  min="0"
                />
                <div *ngIf="chargesFormControls.montantChargeResidencePrin.dirty && chargesFormControls.montantChargeResidencePrin.errors">
                  <span class="help-block" *ngIf="chargesFormControls.montantChargeResidencePrin.errors.required"
                    >Résidence principale (loyer / crédit immobilier) obligatoire</span
                  >
                  <span class="help-block" *ngIf="chargesFormControls.montantChargeResidencePrin.errors.min">Saisie incorrecte</span>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label" for="field_montantChargeResidenceSecon"
                  >Résidence secondaire (loyer / crédit immobilier)*
                  <button mat-icon-button
                          aria-label="info-bulle"
                          matTooltip="Si vous êtes locataire de votre résidence secondaire, indiquer votre loyer y compris les charges. Si vous en êtes
                      propriétaire, indiquez les mensualités de votre prêt immobilier. Veuillez saisir 0 si aucune charge dûe ou aucune
                      résidence secondaire." class="customTooltip customInfoTooltip">
                    <mat-icon class="tooltipStyle">info</mat-icon>
                  </button>
                </label>

                <input
                  type="number"
                  class="form-control"
                  placeholder="€"
                  name="montantChargeResidenceSecon"
                  id="field_montantChargeResidenceSecon"
                  formControlName="montantChargeResidenceSecon"
                  min="0"
                />
                <div
                  *ngIf="chargesFormControls.montantChargeResidenceSecon.dirty && chargesFormControls.montantChargeResidenceSecon.errors"
                >
                  <span class="help-block" *ngIf="chargesFormControls.montantChargeResidenceSecon.errors.required"
                    >Résidence secondaire (loyer / crédit immobilier) obligatoire</span
                  >
                  <span class="help-block" *ngIf="chargesFormControls.montantChargeResidenceSecon.errors.min">Saisie incorrecte</span>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label" for="field_montantCreditsRenouvlable"
                  >Crédits renouvelables*
                  <button mat-icon-button
                          aria-label="info-bulle"
                          matTooltip="Cumul des mensualités de vos crédits renouvelables en cours. Veuillez saisir 0 si aucun crédit renouvelable en
                      cours." class="customTooltip customInfoTooltip">
                    <mat-icon class="tooltipStyle">info</mat-icon>
                  </button>
                </label>

                <input
                  type="number"
                  class="form-control"
                  placeholder="€"
                  name="montantCreditsRenouvlable"
                  id="field_montantCreditsRenouvlable"
                  formControlName="montantCreditsRenouvlable"
                  min="0"
                />
                <div *ngIf="chargesFormControls.montantCreditsRenouvlable.dirty && chargesFormControls.montantCreditsRenouvlable.errors">
                  <span class="help-block" *ngIf="chargesFormControls.montantCreditsRenouvlable.errors.required"
                    >Crédits renouvelables obligatoires</span
                  >
                  <span class="help-block" *ngIf="chargesFormControls.montantCreditsRenouvlable.errors.min">Saisie incorrecte</span>
                </div>
              </div>
            </div>
            <div class="partRight">
              <div class="form-group">
                <label class="control-label" for="field_montantAutresCharges">Autres charges*
                  <button mat-icon-button aria-label="info-bulle"
                    matTooltip="Indiquez les autres charges mensualisées du foyer (pensions alimentaires versées, prélèvement au titre d’un contrat de LOA ou LDD véhicule…). Veuillez saisir 0 si aucune autre charge."
                    class="customTooltip customInfoTooltip">
                    <mat-icon class="tooltipStyle">info</mat-icon>
                  </button>
                </label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="€"
                  name="montantAutresCharges"
                  id="field_montantAutresCharges"
                  formControlName="montantAutresCharges"
                  min="0"
                />
                <div *ngIf="chargesFormControls.montantAutresCharges.dirty && chargesFormControls.montantAutresCharges.errors">
                  <span class="help-block" *ngIf="chargesFormControls.montantAutresCharges.errors.required"
                    >Autres charges obligatoires</span
                  >
                  <span class="help-block" *ngIf="chargesFormControls.montantAutresCharges.errors.min">Saisie incorrecte</span>
                </div>
                <div class="form-group">
                  <label class="control-label" for="field_montantAutresCredits"
                    >Autres crédits*
                    <button mat-icon-button
                            aria-label="info-bulle"
                            matTooltip="Cumul des mensualités de tous vos crédits en cours hors crédit immobilier et hors crédits renouvelables. Veuillez
                        saisir 0 si aucun autre crédit en cours." class="customTooltip customInfoTooltip">
                      <mat-icon class="tooltipStyle">info</mat-icon>
                    </button>
                  </label>
                  <input
                    required
                    type="number"
                    min="0"
                    class="form-control"
                    placeholder="€"
                    name="montantAutresCredits"
                    id="field_montantAutresCredits"
                    formControlName="montantAutresCredits"
                  />
                  <div *ngIf="chargesFormControls.montantAutresCredits.dirty && chargesFormControls.montantAutresCredits.errors">
                    <span class="help-block" *ngIf="chargesFormControls.montantAutresCredits.errors.required"
                      >Autres crédits obligatoires</span
                    >
                    <span class="help-block" *ngIf="chargesFormControls.montantAutresCredits.errors.min">Saisie incorrecte</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="inscriptionValidation">
            <button type="submit" [disabled]="!chargesForm.valid" class="w300 mAuto">
              Valider
            </button>
          </div>
        </form>
      </div>


      <span *ngIf="accesCollaborateur" class="asterisque">(*) Les données ci-dessus sont les dernières informations mises à
                  jour par ce client. Elles peuvent différer de celles ayant prévalues
                  à la création de la simulation.</span>
      <div tabindex="-1" id="messageErrorContainer">
        <div *ngIf="displayBridgeOrClassicButton()" class="inscriptionValidation">
          <button class="w300 mAuto"
                  (click)="showForm('bridgeOrClassic');">
            Valider
          </button>
        </div>


        <p class="messageError" *ngIf="isModifiedIcv && isDashboard && isAllFormValid() && showList">
          Suite à vos modifications, vous devez cliquer sur "Mettre à jour votre Capacité d'emprunt" pour
          recalculer
          votre
          capacité et pouvoir
          accéder à la simulation de projet.
        </p>
        <button type="submit"
                [ngClass]="{ 'modifie padding65': isModifiedIcv && isDashboard, padding65: !isDashboard || !isModifiedIcv }"
                *ngIf="isAllFormValid() && showList" (click)="calculerIcvFinal()">
          {{ buttonWording }}</button>
      </div>
      <br/><br/>
      <span *ngIf="!isDashboard && !accesCollaborateur" class="asterisque">* Informations non contractuelles communiquées sous
                  réserve de l'étude et de l'acceptation de votre demande de crédit par BPCE
                  Financement</span>
    </div>
  </div>
</div>
<div *ngIf="isFromBridge">
  <div class="progress">
    <div class="progressTitleBridge">
      <h2 class="progressTitleBridge" *ngIf="!accesCollaborateur">Vos charges et revenus</h2>
      <h2 class="progressTitleBridge" *ngIf="accesCollaborateur">Vos charges et revenus</h2>

    </div>
  </div>
  <div class="panelBody">
    <div id="bridgeChargesRevenus" *ngIf="true">
      <div class="infosContent-title">
        <div class="previous-text" tabindex="0" appAriaClick
             (click)="showListTabs('charges');tagNavigation('Fermer','Charges');">
          <div class="previous"><span class="texte">Retour</span></div>
        </div>
        <h2 class="h5-flex text-center coleurTextBridge" *ngIf="!accesCollaborateur">Suite à la synchronisation de
          vos
          données bancaires, voici les éléments que nous avons récupérés pour calculer votre
          capacité
          d’emprunt définitive :
          <br/></h2>
        <h2 class="h5-flex text-center" *ngIf="accesCollaborateur">Suite à la synchronisation de vos
          données
          bancaires, voici les éléments que nous avons récupérés pour calculer votre capacité
          d’emprunt
          définitive :
          <br/></h2>
      </div>
      <form *ngIf="bridgeChargeRevenuForm" [formGroup]="bridgeChargeRevenuForm" novalidate>
        <div class="row">
          <p class="mandatory col-12 text-center libelleCompteBridge coleurTextBridge" >{{libelleCompteBridge}}</p>
        </div>

        <div class="completeChamp clearfix">
          <div class="partLeft">
            <div class="form-group">
              <label class="control-label" for="field_montantRevenus"
              >Revenus mensuels*
                <button mat-icon-button
                        aria-label="Revenus mensuels nets avant impôt info-bulle"
                        matTooltip="Suite à la consultation de vos informations bancaires, nous prenons en compte le montant de vos revenus mensuels le plus petit constaté sur les 90 derniers jours." class="customTooltip customInfoTooltip">
                  <mat-icon class="tooltipStyle">info</mat-icon>
                </button>
              </label>
              <input

                      placeholder="€"
                type="number"
                class="form-control"
                name="montantRevenus"
                id="field_montantRevenus_bridge"
                formControlName="montantRevenus"
                min="0"
              />


            </div>
            <div class="form-group">
              <label class="control-label" for="field_montantChargeResidencePrin"
              >Loyer(s) résidence *
                <button mat-icon-button
                        aria-label="info-bulle"
                        matTooltip="Suite à la consultation de vos informations bancaires, nous prenons en compte la moyenne des loyers constatés sur les 90 derniers jours."
                        class="customTooltip customInfoTooltip">
                  <mat-icon class="tooltipStyle">info</mat-icon>
                </button>
              </label>

              <input
                type="number"
                class="form-control"
                placeholder="€"
                name="montantChargeResidencePrin"
                id="field_montantChargeResidencePrin_bridge"
                formControlName="montantChargeResidencePrin"
                min="0"
              />

            </div>
            <div class="form-group">
              <label class="control-label" for="field_montantAutresCredits"
              >Crédit(s)
                <button mat-icon-button
                        aria-label="info-bulle"
                        matTooltip="Suite à la consultation de vos informations bancaires, nous prenons en compte la moyenne de vos mensualités de crédit immobilier et crédit à la consommation constatées sur les 90 derniers jours." class="customTooltip customInfoTooltip">
                  <mat-icon class="tooltipStyle">info</mat-icon>
                </button>
              </label>
              <input
                required
                type="number"
                min="0"
                class="form-control"
                placeholder="€"
                name="montantAutresCredits"
                id="field_montantAutresCredits_bridge"
                formControlName="montantAutresCredits"
              />

            </div>

          </div>
          <div class="partRight">
            <div class="form-group">
              <label class="control-label" for="field_autresRevenus"
              >Autres Revenus /mois*
                <button mat-icon-button
                        aria-label="Autres Revenus /mois info-bulle"
                        matTooltip="Suite à la consultation de vos informations bancaires, nous prenons en compte le montant de vos revenus complémentaires sur les 90 derniers jours."
                        class="customTooltip customInfoTooltip">
                  <mat-icon class="tooltipStyle">info</mat-icon>
                </button>
              </label>
              <input
                placeholder="€"
                type="number"
                class="form-control"
                name="autresRevenus"
                id="field_autresRevenus_bridge"
                formControlName="autresRevenus"
              />

            </div>
            <div class="form-group">
              <label class="control-label" for="field_montantAutresCharges">Autres charges*
                <button mat-icon-button
                        aria-label="Autres charges /mois info-bulle"
                        matTooltip="Suite à la consultation de vos informations bancaires, nous prenons en compte la moyenne de vos charges (exemple : pension alimentaire versée, prélèvement au titre d’un contrat de LOA ou de LDD, etc…) identifiées sur les 90 derniers jours."
                        class="customTooltip customInfoTooltip">
                  <mat-icon class="tooltipStyle">info</mat-icon>
                </button>
              </label>
              <input
                type="number"
                class="form-control"
                placeholder="€"
                name="montantAutresCharges"
                id="field_montantAutresCharges_bridge"
                formControlName="montantAutresCharges"
                min="0"
              />


            </div>
          </div>

        </div>
        <div class="row">
          <h2 class="col-12 text-center coleurTextBridge">Concernant vos impôts, êtes-vous prélevé à la source ?<button mat-icon-button
                                                                                                        aria-label="Prélévement à la source info-bulle"
                                                                                                        matTooltip="Cette information nous permet d'affiner notre proposition pour vous faire la meilleure offre selon votre besoin et votre situation." class="customTooltip customInfoTooltip">
            <mat-icon class="tooltipStyle">info</mat-icon>
          </button></h2>
          <section class="example-section h-100 d-flex align-items-center justify-content-center col-12">
            <mat-checkbox class="example-margin" labelPosition="before"  [checked]="prelevementSourceOui"  (change)="onPrelevementSourceChange($event,1)">Oui</mat-checkbox>
            <mat-checkbox class="example-margin" labelPosition="before"   [checked]="prelevementSourceNon" (change)="onPrelevementSourceChange($event,2)">Non</mat-checkbox>
          </section>
          </div>
        <div class="inscriptionValidation">
          <button type="submit" (click)="calculerIcvFinalBridge()" [disabled]="!bridgeChargeRevenuForm.valid || (prelevementSourceSelected === null)" class="w300 mAuto">
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>


</div>
